
.ant-picker-panels{
    display: inline-flex;
    flex-wrap: nowrap;
    direction: ltr;
    flex-direction: column;
}

@media (min-width: 553px) {
    .ant-picker-panels {
        flex-direction: row;
    }
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }
  
  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }
  
  .ant-picker-panels > *:last-child {
    display: none !important;
  }
  
  .ant-picker-panel-container, .ant-picker-footer {
    width: 280px !important;
  }
  
  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important; 
  }